<template>
  <div>
    <b-row class="justify-content-between align-items-center">
      <b-col md="4">
        <b-overlay :show="isLoading">
          <back-with-title
            :title="accountName"
            route-name="wallet"
            :params="userType === 'company' ? { tab: 'Company Accounts' } : {}"
          />
        </b-overlay>
      </b-col>

      <b-col md="8">
        <b-row class="justify-content-end">
          <!-- Date Range -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>{{ $t('Date Range') }}</label>
              <date-range-filter @change="onDateRange" />
            </b-form-group>
          </b-col>

          <!-- Start Date -->
          <b-col
            v-if="!IS_MOBILE() || usingCustomDateRange"
            cols="12"
            md="auto"
            class="pl-1 pl-md-0 d-flex justify-content-end"
          >
            <b-form-group>
              <label class="mr-1">{{ $t('Start Date') }}</label>
              <div class="d-flex align-items-center">
                <b-form-datepicker
                  v-model="form.start_date"
                  :date-format-options="IS_MOBILE() ? COMMON.MOBILE_DATE_PICKER_FORMAT : COMMON.DATE_PICKER_FORMAT"
                  :max="form.end_date ? form.end_date : TODAY()"
                  :disabled="!usingCustomDateRange"
                />
              </div>
            </b-form-group>

            <!-- End Date -->
            <b-form-group class="ml-1">
              <label class="mr-1">{{ $t('End Date') }}</label>
              <div class="d-flex align-items-center">
                <b-form-datepicker
                  v-model="form.end_date"
                  :min="form.start_date"
                  :max="TODAY()"
                  :disabled="!usingCustomDateRange"
                  :date-format-options="COMMON.DATE_PICKER_FORMAT"
                  :offset="-110"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- statements filter by vendors -->
    <div class="d-flex justify-content-end">
      <b-form-group
        v-if="userType === 'company'"
        class="w-100 w-md-27"
        :label="$t('Filter by vendor')"
      >
        <v-select
          v-model="selectedVendors"
          :options="vendorOptions"
          :placeholder="$t('Filter')"
          :select-on-key-codes="[13]"
          taggable
          @search="onVendorsFilter"
          @input="getWalletStatement"
        >
          <!-- Selected Option -->
          <template #selected-option="{ label, type }">
            <div class="d-flex align-items-center">
              <b-avatar>
                <i
                  :class="getVendorIcon(type, type)"
                  class="font-18"
                />
              </b-avatar>
              <span class="ml-1">{{ label }}</span>
            </div>
          </template>

          <!-- Options -->
          <template #option="{ label, type }">
            <div class="d-flex align-items-center">
              <div
                v-if="type"
                class="d-flex"
              >
                <b-avatar class="mr-1">
                  <i
                    :class="getVendorIcon(type)"
                    class="font-18"
                  />
                </b-avatar>
                <div>
                  <p class="font-weight-bold mb-0">
                    {{ label }}
                  </p>
                  <span class="text-muted">{{ type }}</span>
                </div>
              </div>
              <div v-else>
                <b-avatar>
                  <i class="font-18 fas fa-search" />
                </b-avatar>
                &nbsp; Look for <span class="font-weight-bold">{{ label }}</span>
              </div>
            </div>
          </template>

          <!-- No Options -->
          <template #no-options>
            <i class="fas fa-keyboard" />
            {{ $t('messages.type-to-filter') }}
          </template>

          <!-- Loading -->
          <template #list-footer>
            <li
              v-if="vendorsLoading"
              style="text-align: center"
            >
              <b-spinner class="my-1" />
            </li>
          </template>
        </v-select>
      </b-form-group>
    </div>

    <!-- statements -->
    <b-overlay
      :show="isLoading"
      class="pb-1"
    >
      <b-table
        id="transactions-table"
        :fields="fields"
        :items="groupedTransaction"
        show-empty
        responsive
        striped
      >
        <template #head()="data">
          <span class="text-capitalize py-2">
            {{ data.label }}
          </span>
        </template>

        <template #head(actions)>
          <feather-icon
            v-if="userType === 'company'"
            v-b-tooltip.hover.top="$t('Export Statement')"
            icon="DownloadIcon"
            class="cursor-pointer"
            role="button"
            size="20"
            @click="onExport"
          />
        </template>

        <template #cell(description)="data">
          <div class="d-flex">
            <feather-icon
              v-if="data.index !== 0 && data.index !== groupedTransaction.length - 1"
              icon="CalendarIcon"
              class="mr-1"
              size="20"
            />
            <h5 :class="data.item.hasDetails ? '' : 'my-1'">
              {{ data.item.key }}
            </h5>
          </div>
          <div v-if="data.item.hasDetails">
            <div
              v-for="transaction, index in data.item.value"
              :key="index"
              class="statement-row"
            >
              <div class="d-flex mt-1 ml-1">
                <b-avatar
                  v-b-tooltip.hover="transaction.transactionType.__typename === 'ReconciliationTransaction' ? $t('Manual Transaction') : ''"
                  size="28"
                  class="mr-1"
                >
                  <img
                    v-if="transaction.transactionType.__typename === 'ReconciliationTransaction' || transaction.transactionType.__typename === 'WalletToWalletTransfer'"
                    :src="require(`@/assets/icons/${getTransactionIcon(transaction.transactionType.__typename)}`)"
                    width="20"
                    alt=""
                  >
                  <i
                    v-else
                    :class="getWalletIcon(transaction.walletShare.wallet.__typename)"
                  />
                </b-avatar>
                <div class="statement-col">
                  <!-- Wallet to wallet transfer -->
                  <div v-if="transaction.transactionType.__typename === 'WalletToWalletTransfer' && transaction.transactionType.destination && transaction.transactionType.destination.__typename === 'User'">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        style="margin-bottom: 4px; margin-right: 4px;"
                        icon="UserIcon"
                      />
                      <div>
                        {{ transaction.transactionType.destination.name }} - &nbsp;
                        <span class="font-weight-bold">
                          {{ transaction.description }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- Vendor Info -->
                  <div v-else-if="transaction.vendorInfo && transaction.vendorInfo.vendor">
                    <div class="d-flex align-items-baseline">
                      <i
                        :class="getVendorIcon(transaction.vendorInfo.vendor.__typename)"
                        style="margin-right: 4px;"
                      />
                      <div>
                        {{ transaction.vendorInfo.vendor.name }} - &nbsp;
                        <span class="font-weight-bold">
                          {{ transaction.description }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- Transaction Component -->
                  <component
                    :is="transaction.transactionType.__typename"
                    v-if="transaction.transactionType"
                    :transaction="transaction"
                    :user-type="userType"
                  />
                  <span v-if="transaction.transactionType.document">
                    <b-link
                      v-b-tooltip.hover.bottom="$t('View Attachment')"
                      class="cursor-pointer small"
                      @click="openAttachment(transaction.transactionType.document)"
                    >
                      <feather-icon
                        icon="PaperclipIcon"
                      /> {{ $t('Attachment') }}
                    </b-link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #cell(debit)="data">
          <div v-if="data.item.hasDetails">
            <div
              v-for="transaction in data.item.value"
              :key="transaction.uuid"
              class="statement-col my-1 text-right pr-4"
            >
              <p
                v-if="transaction.amount < 0"
                class="pt-2"
              >
                {{ formatAmount(transaction.amount*(-1)) }}
              </p>
              <p
                v-else
                class="pt-2"
              >
                &mdash;
              </p>
            </div>

          </div>
        </template>
        <template #cell(credit)="data">
          <div v-if="data.item.hasDetails">
            <div
              v-for="transaction in data.item.value"
              :key="transaction.uuid"
              class="statement-col my-1 text-right pr-4"
            >
              <p
                v-if="transaction.amount >= 0"
                class="pt-2"
              >
                {{ formatAmount(transaction.amount) }}
              </p>
              <p
                v-else
                class="pt-2"
              >
                &mdash;
              </p>
            </div>
          </div>
        </template>

        <template #cell(amount)="data">
          <div v-if="data.item.hasDetails">
            <div
              v-for="transaction in data.item.value"
              :key="transaction.uuid"
              class="statement-col my-1 text-right pr-4"
            >
              <p class="pt-2">
                {{ formatAmount(transaction.balance) }}
              </p>
            </div>

          </div>
          <div v-else>
            <p class="text-right pr-4">
              {{ formatAmount(data.item.value) }}
            </p>
          </div>
        </template>

        <template #cell(actions)="data">
          <div v-if="data.item.hasDetails">
            <div
              v-for="transaction in data.item.value"
              :key="transaction.uuid"
              class="statement-col my-1 text-right pr-4"
            >
              <statement-actions
                v-if="hasActions(transaction)"
                :transaction-type="transaction.transactionType.__typename"
                :wallet-type="transaction.walletShare.wallet.__typename"
                :destination="transaction.transactionType.destination ? transaction.transactionType.destination.__typename : null"
                @update-transaction="showModal(transaction, 'reconcile-transaction-0-company-modal')"
                @delete-manual-transaction="showModal(transaction, 'delete-transaction-modal')"
                @delete-company-transaction="showModal(transaction, 'delete-company-transaction-modal')"
                @edit-allocation="showModal(transaction, 'edit-allocations-modal')"
              />
            </div>
          </div>
        </template>
      </b-table>
    </b-overlay>

    <b-pagination
      v-if="total > perPage"
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      class="float-right p-2"
      aria-controls="transactions-table"
      :disabled="isLoading"
      @input="getWalletStatement"
    />

    <confirm-modal
      v-if="userType === 'company'"
      :title="$t('Delete Transaction')"
      modal="delete-transaction-modal"
      :alert="$t('messages.delete-transaction-confirmation')"
      alert-variant="danger"
      :ok-title="$t('Delete')"
      :cancel-title="$t('Cancel')"
      ok-variant="danger"
      icon="AlertTriangleIcon"
      icon-color="danger"
      @confirm="deleteTransaction"
    />

    <make-edit-reconciliation
      v-if="userType === 'company'"
      :index="0"
      mode="update"
      user-type="company"
      :selected-transaction="selectedTransaction"
      @reconciliation-success="getWalletStatement"
      @onClose="selectedTransaction = null"
    />

    <edit-allocations
      v-if="userType === 'company' && selectedTransaction"
      :wallet-uid="payrollWalletUid"
      :transaction="selectedTransaction"
      :allocations="payrollWalletOptions"
      :is-loading="loadingCompanyPayrollWallets"
      @close="selectedTransaction = null"
      @allocation-updated="getWalletStatement"
    />

    <delete-company-transaction
      v-if="userType === 'company' && selectedTransaction"
      :transaction-uid="selectedTransaction.uuid"
      @transaction-deleted="getWalletStatement"
    />
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BRow, BCol, BButton, BTable, BPagination, BOverlay, BFormGroup, BFormDatepicker, BCard, BAvatar, BLink, VBTooltip, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import DateRangeFilter from '@/views/common/components/DateRangeFilter.vue'
import BackWithTitle from '@/views/common/components/BackWithTitle.vue'
import useWallet from '@/utils/wallet'
import moment from 'moment'
import vSelect from 'vue-select'
import _ from 'lodash'
import AdminDeposit from './Transactions/AdminDeposit.vue'
import WalletToWalletTransfer from './Transactions/WalletToWalletTransfer.vue'
import WalletToBank from './Transactions/WalletToBank.vue'
import BankTransferSuccess from './Transactions/BankTransferSuccess.vue'
import StripeDeposit from './Transactions/StripeDeposit.vue'
import ReconciliationTransaction from './Transactions/ReconciliationTransaction.vue'
import ConfirmModal from './reusables/ConfirmModal.vue'
import MakeEditReconciliation from './WalletActions/MakeEditReconciliation.vue'
import EditAllocations from './WalletActions/EditAllocations.vue'
import exportStatement from './WalletActions/exportStatement'
import StatementActions from './StatementActions.vue/StatementActions.vue'
import DeleteCompanyTransaction from './WalletActions/DeleteCompanyTransaction.vue'

const { getWalletIcon, getTransactionIcon, getVendorIcon } = useWallet()

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BOverlay,
    BFormGroup,
    BFormDatepicker,
    BCard,
    BAvatar,
    BSpinner,
    BLink,
    BDropdown,
    BDropdownItem,
    AdminDeposit,
    WalletToWalletTransfer,
    WalletToBank,
    BankTransferSuccess,
    DateRangeFilter,
    BackWithTitle,
    StripeDeposit,
    ReconciliationTransaction,
    MakeEditReconciliation,
    ConfirmModal,
    EditAllocations,
    StatementActions,
    DeleteCompanyTransaction,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      getWalletIcon,
      getTransactionIcon,
      getVendorIcon,
      form: {
        start_date: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: this.maxDate,
      },
      company: this.$store.state.project.selectedCompany,
      transactions: [],
      perPage: 10,
      currentPage: 1,
      total: 0,
      isLoading: false,
      fields: [
        { key: 'description', label: this.$t('Description'), tdClass: 'col-360 p-1' },
        { key: 'debit', label: this.$t('Dr.'), thStyle: 'width:220px; text-align:right; padding-right: 5rem' },
        { key: 'credit', label: this.$t('Cr.'), thStyle: 'width:220px; text-align:right; padding-right: 5rem' },
        { key: 'amount', label: this.$t('Balance'), thStyle: 'width:220px; text-align:right; padding-right: 5rem' },
        {
          key: 'actions', label: '', thStyle: 'text-align:right;', tdClass: 'actions',
        },
      ],
      usingCustomDateRange: false,
      accountName: '',
      openingBalance: 0,
      closingBalance: 0,
      selectedTransaction: null,
      payrollWalletUid: '',
      payrollWalletOptions: [],
      loadingCompanyPayrollWallets: false,
      selectedVendors: [],
      vendorOptions: [],
      vendorsLoading: false,
    }
  },
  computed: {
    maxDate() {
      return this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start
    },
    groupedTransaction() {
      const group = []
      if (this.transactions) {
        if (this.transactions.length) {
          const transactions = this.transactions.map(transaction => ({
            ...transaction,
            key: this.FORMAT_DATE(this.UTCtoLocal(transaction.transactionDate)),
          }))
          let currentBalance = this.openingBalance
          Object.entries(this.GROUP_BY(transactions, 'key')).forEach(([key, value]) => {
            group.push({
              hasDetails: true,
              key,
              value: value.map(transaction => {
                currentBalance += transaction.amount
                return {
                  ...transaction,
                  balance: currentBalance,
                }
              }),
            })
          })
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.closingBalance = currentBalance
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.openingBalance = this.closingBalance
        }
      }

      group.unshift({
        key: this.$t('Opening Balance'),
        value: this.openingBalance ?? 0,
      })
      group.push({
        key: this.$t('Closing Balance'),
        value: this.closingBalance ?? 0,
      })
      return group
    },
    userType() {
      return this.$route.params.userType
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.getWalletStatement()
      },
    },
  },
  mounted() {
    this.getWalletStatement()
    // eslint-disable-next-line no-restricted-globals
    onload = () => {
      this.$router.replace({ name: 'wallet' })
    }
  },
  methods: {
    onExport() {
      let type = 'company'
      let variables = {
        first: this.perPage,
        page: this.currentPage,
        startTime: moment(this.form.start_date).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(this.form.end_date).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
        company: this.company,
      }
      let filename = this.accountName.split(' ').join('_')
      filename += `_Statement_${this.FORMAT_DATE(new Date(), 'YYYY_MM_DD_HH_mm')}.xlsx`

      if (this.$route.params.account) {
        type = 'company-share'
        variables = {
          ...variables,
          keyword: this.$route.params.account,
          walletUid: this.$route.params.walletFamily,
        }
      }

      exportStatement(type, JSON.stringify(variables), filename).catch(() => {
        this.showErrorMessage('Something went wrong!')
      })
    },
    onDateRange(range) {
      if (!range.startTime && !range.endTime) {
        this.usingCustomDateRange = true
        return null
      }
      this.form.start_date = range.startTime
      this.form.end_date = this.ADD_DAYS(range.endTime, 1)
      this.usingCustomDateRange = false
      return null
    },
    getWalletStatement() {
      const filterInput = this.selectedVendors?.type ? {
        vendorSearchKey: 'TRANSACTIONVENDOR',
        vendorType: this.selectedVendors.type.toUpperCase(),
        vendorKeyword: this.selectedVendors.uuid,
      } : {
        vendorSearchKey: 'NAME',
        vendorKeyword: this.selectedVendors?.label ?? '',
        vendorType: 'USER',
      }

      let params = {
        first: this.perPage,
        page: this.currentPage,
        startTime: moment(this.form.start_date).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(this.form.end_date).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
      }

      // For User Overall Transactions
      let api = 'getUserTransactions'
      let res = 'me'

      // For Company Overall Transaction
      if (this.userType === 'company') {
        api = 'getCompanyTransactions'
        params = {
          ...params,
          ...filterInput,
          company: this.company,
        }
        res = 'company'
      }

      if (!this.$route.params.overall) {
        // For Individual Share Transaction
        if (this.$route.params.account) {
          api = this.userType === 'company' ? 'getCompanyShareTransactions' : 'getUserShareTransactions'
          params = {
            ...params,
            keyword: this.$route.params.account,
            walletUid: this.$route.params.walletFamily,
          }
        } else {
          api = this.userType === 'company' ? 'getCompanyWalletTransactions' : 'getUserWalletTransactions'
          params = {
            ...params,
            walletUid: this.$route.params.walletFamily,
          }
        }
      }

      this.isLoading = true
      useApollo[this.userType][api](params).then(response => {
        let data
        if (this.$route.params.overall) {
          data = response.data?.[res]
          let company = ''
          if (this.userType === 'company') {
            company = this.$route.params.company?.name ?? 'Your Company'
          }

          this.accountName = this.userType === 'users' ? 'Your Account' : company
          this.transactions = data.walletTransactions?.data
          this.total = data?.walletTransactions?.total
        } else {
          data = response.data[res].wallets.data[0].shares ? response.data[res].wallets.data[0].shares[0] : response.data[res].wallets.data[0]

          this.accountName = data.description !== '' ? data.description : 'Reconciliation Account'
          this.transactions = data.transactions?.data
          this.total = data.transactions.total
        }
        this.openingBalance = data.openingBalance
        this.closingBalance = data.balance
      }).finally(() => { this.isLoading = false })
    },
    getCompanyPayrollWallets() {
      this.loadingCompanyPayrollWallets = true
      useApollo.company.getCompanyPayrollWallets({
        company: this.company,
      }).then(response => {
        const { wallet } = response.data.company

        this.payrollWalletUid = wallet.uuid
        this.payrollWalletOptions = wallet.shares.map(({ uuid, description }) => ({
          value: uuid,
          label: description,
        }))
      }).finally(() => {
        this.loadingCompanyPayrollWallets = false
      })
    },
    openAttachment(url) {
      window.open(url, '_blank', 'noreferrer')
    },
    showModal(transaction, modal) {
      if (modal === 'edit-allocations-modal' && !this.payrollWalletOptions.length) this.getCompanyPayrollWallets()
      this.selectedTransaction = transaction
      this.$nextTick(() => { this.$bvModal.show(modal) })
    },
    deleteTransaction() {
      this.isLoading = true
      const params = {
        companyUid: this.company,
        transactionUid: this.selectedTransaction.uuid,
        action: 'DELETE',
      }

      useApollo.company.updateCompanyReconciliationTransaction(params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.updateCompanyReconciliationTransaction.message,
          },
        })
        this.getWalletStatement()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
    hasActions(transaction) {
      if (transaction.transactionType.__typename === 'ReconciliationTransaction') return true
      if (transaction.walletShare.wallet.__typename === 'PayrollWallet') return true
      if (transaction.transactionType.__typename === 'WalletToWalletTransfer' && transaction.transactionType.destination.__typename === 'Company') return true
      return false
    },
    onVendorsFilter(value) {
      if (value) {
        this.vendorsLoading = true
        this.getVendors(value)
      }
    },
    getVendors: _.debounce(function (value) {
      const params = {
        companyUid: this.company,
        keyword: value,
      }

      useApollo.company.getCompanyVendors(params).then(response => {
        this.vendorOptions = response.data.company.vendors.data.map(({ vendor }) => ({
          uuid: vendor.uuid,
          label: vendor.name,
          type: vendor.__typename,
        }))
      }).finally(() => {
        this.vendorsLoading = false
      })
    }, 1000),
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/wallet-page.scss";
.actions{
  width: 20px !important;
  padding: 0 !important;
}
.font-18 {
  font-size: 18px;
}
.vs__dropdown-toggle {
  height: 48px;
}
@media screen and (max-width: 768px) {
  .nav-pills .nav-link {
    padding: 0.5rem 0.75rem;
  }
}
@media screen and (min-width: 768px) {
  .w-md-27 {
    width: 27% !important;
  }
}
</style>
